import { Injectable , OnDestroy, inject} from '@angular/core';
import ShortUniqueId from 'short-unique-id';

// firestore functions 
// DocumentData, addDoc, updateDoc, deleteDoc, DocumentReference, 
import { Firestore, doc, getDoc, setDoc, getDocs, updateDoc, collection, query, where} from '@angular/fire/firestore';
import { Storage , getDownloadURL, ref, uploadBytesResumable} from '@angular/fire/storage';
import { Auth, authState , User} from '@angular/fire/auth';
import { Subscription } from 'rxjs'; 
import { UserProfile, BusinessProfile, Business, BusinessUser} from "../interfaces/users-interface";
import { isThisSecond } from 'date-fns';


@Injectable({
  providedIn: 'root',
})
export class UserProfileService implements OnDestroy {
  private auth: Auth = inject(Auth);
  private storage: Storage = inject(Storage); // inject Firebase Storage
  authState$ = authState(this.auth);
  authStateSubscription: Subscription;

  private firestore: Firestore = inject(Firestore); // inject Cloud Firestore
  public loggedIn: boolean = false;
  public userProfile: UserProfile = {
    userID: '',
    nameFirst: '',
    nameMiddle: '',
    nameLast: '',
    birthdate: new Date(),
    email: '',
    address: {
      street: '',
      city: '',
      barangay: '',
      province: '',
      zipcode: '',
    },
    cellphone: '',
    profileImageURL: '',
    governmentID: {
      idType: '',
      referenceNumber: '',
      imageURL: '',
      nameOnID: '',
      addressOnID: {
        street: '',
        city: '',
        barangay: '',
        province: '',
        zipcode: '',
      },
    },
    philSysPCN: '',
    philSysToken: '',
    rsbsaNumber: '',
    tin: '',
  };

  public businessProfile: BusinessProfile = {
    starRating: 0,
    numberOfTrades: 0,
    averageValueOfTrades: 0,
    accreditations: [],
    affiliations: [],
  };

  public business: Business = {
    businessID: '',
    businessName: '',
    phone:'',
    address: {
      street: '',
      city: '',
      barangay: '',
      province: '',
      zipcode: '',
    },
    secNumber: '',
    tin: '',
    bankAccount: {
      bankName: '',
      accountNumber: '',
      accountName: '',
      accountType: '',
    },
  };

  public businessUser: BusinessUser = {
    businessID: '',
    userID: '',
  };

  constructor() {
    console.log("UserProfileService:constructed");
    this.authStateSubscription = this.authState$.subscribe(
      (aUser: User | null) => {
        //handle auth state changes here. Note, that user will be null if there is no currently logged in user.
        console.log(aUser);
        if (aUser === null) {
          this.loggedIn = false;
        } else {
          this.loggedIn = true;
        }
      }
    );
  }

  resetUser(){
    console.log("UserProfileService: resetUser()")
    this.userProfile = {
      userID: '',
      nameFirst: '',
      nameMiddle: '',
      nameLast: '',
      birthdate: new Date(),
      email: '',
      address: {
        street: '',
        city: '',
        barangay: '',
        province: '',
        zipcode: '',
      },
      cellphone: '',
      profileImageURL: '',
      governmentID: {
        idType: '',
        referenceNumber: '',
        imageURL: '',
        nameOnID: '',
        addressOnID: {
          street: '',
          city: '',
          barangay: '',
          province: '',
          zipcode: '',
        },
      },
      philSysPCN: '',
      philSysToken: '',
      rsbsaNumber: '',
      tin: '',
    };
    this.blankBusiness();
  }


  getUID(){
    let uid = this.auth.currentUser?.uid;
    if(uid === undefined) return "";
    else return uid; 
  }

  getUserLevel(){
    if(this.loggedIn){
      if(this.business.businessID > ""){
        return "Tier2";
      }else{
        return "Tier1";
      }
    }else{
      return "Tier0";
    }
  }

  // check if it is a valid User
  // userInfo has the fields of the User in Firebase Auth
  // displayName
  // email
  // photoURL
  // phoneNumber
  // uid
  checkUser(userInfo: any) {
    console.log("checkUser");
    return new Promise((resolve, reject)=>{
      if (userInfo !== null && userInfo !== undefined) {
        console.log("checkUser:", userInfo['uid']);
        // const userProfileDoc = doc(
        //   this.firestore,
        //   '/userProfiles/' + userInfo['uid']
        // );
        
        this.getUserProfile(userInfo.uid).then((userProfile) => {
          console.log("checkUser:", userProfile)
          if (userProfile) {
            // apply userInfo
            console.log('Apply User Profile', userProfile);
            Object.assign(this.userProfile, userProfile);
            resolve({success:true, msg:"a user profile is loaded"});
          } else {
            console.log('Initializing a new UserProfile');
            this.initializeUserProfile(userInfo);
            this.setUserProfile(userInfo['uid']);
            resolve({success:true, msg:"a new user profile is created"});
          }
        }, err=>{
          console.log("error while getting User Profile", err);
        });
      }else{
        reject({success:false, msg:"invalid userInfo"})
      }
    });


  }

  async getUserProfile(uid = null) {
    console.log("getUserProfile()", uid);
    if (uid === null || uid === undefined) return false;
    // set the reference
    const userProfileDoc = doc(this.firestore, '/userProfiles/' + uid);
    // get the doc
    let userProfile = await getDoc(userProfileDoc);
    console.log("getUserProfile", userProfile);
    if (userProfile.exists()) {
      console.log('Document data:', userProfile.data());
      let userProfileData = await userProfile.data();
      return userProfileData;
    } else {
      // userProfile.data() will be undefined in this case
      console.log('No such document!');
      return false;
    }
  }

  initializeUserProfile(userInfo: any) {
    this.blankUserProfile();
    const uid = new ShortUniqueId({ length: 6, dictionary: 'alphanum_upper' });
    console.log("Checking if we can generate UUID's", uid.rnd());
    this.userProfile.email = userInfo.email;
    this.userProfile.nameFirst = userInfo.displayName;
    this.userProfile.cellphone =
      userInfo.phoneNumber || this.userProfile.cellphone;
    this.userProfile.userID = uid.rnd();
    console.log('Initialized new UserProfile', this.userProfile);
  }

  setUserProfile(uid: string) {
    return new Promise((resolve, reject)=>{
      const userProfileDoc = doc(this.firestore, '/userProfiles/' + uid);
      setDoc(userProfileDoc, this.userProfile).then(() => {
        console.log('Done setting UserProfile Doc');
        resolve({success:true, msg:"created new business"})
      }, err=>{
        reject({success:false, msg:"error while creating new business", err:err})
      });
    });

  }
  
  getImageURL(){
    let imageURL = "./assets/images/avatars/farmer-icon.jpg"
    if(this.userProfile.profileImageURL > "" ){
      imageURL = this.userProfile.profileImageURL;
    } 
    return imageURL; 
  }

  updateUserProfile(newProfileInfo: any) {
    let uid = this.auth.currentUser?.uid;
    return new Promise( (resolve, reject) =>{
      if (uid !== undefined) {
        console.log('updateProfile', uid, newProfileInfo);
        const userProfileDoc = doc(this.firestore, '/userProfiles/' + uid);
        Object.assign(this.userProfile, newProfileInfo);
        // choose only which fields to update
        let updateFields = {
          nameFirst: this.userProfile.nameFirst,
          nameMiddle: this.userProfile.nameMiddle,
          nameLast: this.userProfile.nameLast,
          birthdate: this.userProfile.birthdate,
          address: this.userProfile.address,
          cellphone: this.userProfile.cellphone,
          philSysPCN: this.userProfile.philSysPCN,
          philSysToken: this.userProfile.philSysToken,
          rsbsaNumber: this.userProfile.rsbsaNumber,
          tin: this.userProfile.tin,
        };
  
        updateDoc(userProfileDoc, updateFields).then(() => {
          console.log('Done updating UserProfile Doc');
          resolve({status:true});
        }, err=>{
          if(err.msg)
          reject({status:false, msg:"Unable to update", err:err})
        });
      } else {
        console.log('updateProfile: no logged in User', uid, newProfileInfo);
        reject({status:false, msg:"No user logged in"});
      }
    })
    
  }

  /** upload an ID file of the user to firebase storage */
  uploadUserIDFile(file:any){ 
    return new Promise((resolve, reject)=>{
      let uid = this.auth.currentUser?.uid;
      if(uid !== undefined){
        
        if(file){
          const storageRef = ref(this.storage, '/userProfiles/' + uid + '/governmentID');
          uploadBytesResumable(storageRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!', snapshot);
            getDownloadURL(snapshot.ref).then((downloadURL) => {
              console.log('File available at', downloadURL);
              this.userProfile.governmentID.imageURL = downloadURL;
              resolve({success:true, msg:"uploaded file", downloadURL:downloadURL});
            });
          });
        }
      }else{
        reject({success:false, msg:"No user logged in"});
      }
    });
  }

  uploadSelfie(file:any){ 
    return new Promise((resolve, reject)=>{
      let uid = this.auth.currentUser?.uid;
      if(uid !== undefined){
        
        if(file){
          const storageRef = ref(this.storage, '/userProfiles/' + uid + '/selfie');
          uploadBytesResumable(storageRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!', snapshot);
            getDownloadURL(snapshot.ref).then((downloadURL) => {
              console.log('File available at', downloadURL);
              this.userProfile.governmentID.imageURL = downloadURL;
              resolve({success:true, msg:"uploaded file", downloadURL:downloadURL});
            });
          });
        }
      }else{
        reject({success:false, msg:"No user logged in"});
      }
    });
  }

  blankBusiness(){
    console.log("UserProfileService: blankBusiness()");
    this.business = {
      businessID: '',
      businessName: '',
      phone:'',
      address: {
        street: '',
        city: '',
        barangay: '',
        province: '',
        zipcode: '',
      },
      secNumber: '',
      tin: '',
      bankAccount: {
        bankName: '',
        accountNumber: '',
        accountName: '',
        accountType: '',
      },
    };

    this.businessProfile = {
      starRating: 0,
      numberOfTrades: 0,
      averageValueOfTrades: 0,
      accreditations: [],
      affiliations: [],
    };
  }

  selfBusiness(){
    // the user is using his personal identity to do business in LAKAL
    console.log("selfBusiness");
    let selfBusiness = {
      businessName: this.userProfile.nameFirst + ' ' + this.userProfile.nameMiddle + ' ' + this.userProfile.nameLast,
      phone: this.userProfile.cellphone,
      tin:this.userProfile.tin
      
    }
    Object.assign(this.business, selfBusiness, {address:this.userProfile.address});
  }

  checkBusiness(){
    console.log("checkBusiness()")
    return new Promise((resolve, reject)=>{
      if(this.business.businessID ==""){
        // search for business in BusinessUsers
        this.checkBusinessUser().then((result:any)=>{
          if(result['success']==true){
            // then load business
            const bid = this.businessUser.businessID;
            this.getBusiness(bid).then((businessInfo:any)=>{
              Object.assign(this.business, businessInfo);
              console.log("checkBusiness: Business Info loaded", businessInfo);
              this.getBusinessProfile(this.business.businessID).then((businessProfile:any)=>{
                console.log("Got Business Profile", businessProfile);
                this.businessProfile = businessProfile;

                resolve(result);
              }, err=>{
                reject(err);
              });
            });
          }else{
            console.log("checkBusiness: none found ")
            reject(result);
          }
        }, err=>{
          console.log("checkBusiness: no result for Business User", err);
        })
      }else{
        // then it should have been loaded already
        this.getBusinessProfile(this.business.businessID).then((businessProfile:any)=>{
          console.log("Got Business Profile", businessProfile);
          this.businessProfile = businessProfile;
          resolve({success:true, msg:"business info already loaded"});
        }, err=>{
          resolve({success:true, msg:"business info already loaded"});
        });
      }
    });
  }

  setBusiness(bid: string) {
    console.log("setBusiness:bid", bid);
    return new Promise((resolve, reject)=>{
      if(bid != ''){
        const businessDoc = doc(this.firestore, '/business/' + bid);
        setDoc(businessDoc, this.business).then(() => {
          console.log('setBusiness: Done setting Business Doc');
          this.setNewBusinessUser();
          this.setBusinessProfile(bid);
          resolve({success:true, msg:"created new business"})
        }, err=>{
          reject({success:false, msg:"error while creating new business", err:err})
        });
      }else{
        reject({success:false, msg:"business ID not set"});
      }
     
    });
  }

  async getBusiness(bid:string | null | undefined = null) { 
    if (bid === null || bid === undefined) return false;
    // set the reference
    const businessDoc = doc(this.firestore, '/business/' + bid);
    // get the doc
    let business = await getDoc(businessDoc);
    if (business.exists()) {
      console.log('getBusiness: Document data:', business.data());
      let businessData = await business.data();
      return businessData;
    } else {
      // business.data() will be undefined in this case
      console.log('getBusiness: No such document!');
      return false;
    }
  }

  updateBusiness(newBusinessInfo: any) {
    console.log("updateBusiness: Business is", this.business);
    if(this.business.businessID == ""){
      const uid = new ShortUniqueId({ length: 6, dictionary: 'alphanum_upper' });
      this.business.businessID = uid.rnd();
      return this.setBusiness(this.business.businessID);
    }else {
      let bid = this.business.businessID;
      let updateFields = {};
      return new Promise( (resolve, reject) =>{
        console.log('updateBusiness:', bid, newBusinessInfo);
        const businessDoc = doc(this.firestore, '/business/' + bid);
        Object.assign(this.business, newBusinessInfo);
        // choose only which fields to update
        Object.assign(updateFields, {
          businessName: this.business.businessName,
          phone: this.business.phone,
          address: this.business.address,
          secNumber: this.business.secNumber,
          tin: this.business.tin,         
        });
        
        updateDoc(businessDoc, updateFields).then(() => {
          console.log('updateBusiness: Done updating Business Doc');
          resolve({success:true});
        }, err=>{
          reject({success:false, msg:"Unable to update", err:err})
        });

      })
    }
    
    
  }

  blankBusinessProfile(): BusinessProfile{
    // return a blank BusinessProfile
    let blank: BusinessProfile = {
      starRating: 5,
      numberOfTrades: 99,
      averageValueOfTrades: 99999,
      accreditations: [],
      affiliations: []
    }
    return blank;

  }
  async getBusinessProfile(bid:any):Promise<BusinessProfile> {
    if (bid === null || bid === undefined) return this.blankBusinessProfile();
    // set the reference
    const businessProfileDoc = doc(this.firestore, '/businessProfiles/' + bid);
    // get the doc
    let businessProfile = await getDoc(businessProfileDoc);
    if (businessProfile.exists()) {
      console.log('Document data:', businessProfile.data());
      let businessProfileData = await businessProfile.data();
      // check for new fields 
      if(businessProfileData['starRating'] === undefined || businessProfileData['starRating'] == ''){
        businessProfileData['starRating'] = 5;
      }
      if(businessProfileData['numberOfTrades'] === undefined || businessProfileData['numberOfTrades'] == ''){
        businessProfileData['numberOfTrades'] = 99;
      }
      if(businessProfileData['averageValueOfTrades'] === undefined || businessProfileData['averageValueOfTrades'] == ''){
        businessProfileData['averageValueOfTrades'] = 99999;
      }
      if(businessProfileData['accreditations'] === undefined || businessProfileData['accreditations'] == ''){
        businessProfileData['accreditations'] = [];
      }
      if(businessProfileData['affiliations'] === undefined || businessProfileData['affiliations'] == ''){
        businessProfileData['affiliations'] = [];
      }
      return (businessProfileData as BusinessProfile);
    } else {
      // businessProfile.data() will be undefined in this case
      console.log('No such document!');
      return this.blankBusinessProfile();
    }
  }

  checkBusinessUser(){
    return new Promise((resolve, reject)=>{
        if(this.userProfile.userID !== ""){
        const buCollectionRef = collection(this.firestore,"/businessUsers");
        let buQuery = query(buCollectionRef, where("userID", "==", this.userProfile.userID));
        getDocs(buQuery).then(querySnapshot=>{
          if(querySnapshot.empty){
            //this.setNewBusinessUser();
            reject({success:false, msg:"did not find Business User entry", querySnapshot:querySnapshot});
          }else{
            querySnapshot.forEach((doc:any) => {
              // doc.data() is never undefined for query doc snapshots
              console.log("checkBusinessUser: found a business user", doc.id, " => ", doc.data(), querySnapshot.empty);
              Object.assign(this.businessUser, doc.data());
              console.log("checkBusinessUser: setting values",this.businessUser);
            });            
            resolve({success:true,msg:"Found a Business User", businessUser:this.businessUser});
          }
        }, err=>{
        });
        
      }
    });

  }

  setNewBusinessUser() {
    console.log("setNewBusinessUser");
    const bid = this.business.businessID;
    const userID = this.userProfile.userID;
    if(bid != ""){
      const businessUserDoc = doc(this.firestore, '/businessUsers/' + bid + userID);
      let newBusinessUser = {businessID:bid, userID:this.userProfile.userID};
      setDoc(businessUserDoc, newBusinessUser).then(() => {
        console.log('setNewBusinessUser: Done setting newBusinessUser Doc');
      });
    }else{
      console.log("setNewBusinesUser: belay this,business not set")
    }

  }

  async getBusinessUser(bid = null) {
    if (bid === null || bid === undefined) return false;
    // set the reference
    const businessUserDoc = doc(this.firestore, '/businessUsers/' + bid);
    // get the doc
    let businessUser = await getDoc(businessUserDoc);
    if (businessUser.exists()) {
      console.log('getBusinessUser: Document data:', businessUser.data());
      let businessUserData = await businessUser.data();
      return businessUserData;
    } else {
      // businessUser.data() will be undefined in this case
      console.log('getBusinessUser: No such document!');
      return false;
    }
  }

  blankUserProfile() {
    let blankUserProfile: UserProfile = {
      userID: '',
      nameFirst: '',
      nameMiddle: '',
      nameLast: '',
      birthdate: new Date(),
      email: '',
      address: {
        street: '',
        city: '',
        barangay: '',
        province: '',
        zipcode: '',
      },
      cellphone: '',
      profileImageURL: '',
      governmentID: {
        idType: '',
        referenceNumber: '',
        imageURL: '',
        nameOnID: '',
        addressOnID: {
          street: '',
          city: '',
          barangay: '',
          province: '',
          zipcode: '',
        },
      },
      philSysPCN: '',
      philSysToken: '',
      rsbsaNumber: '',
      tin: '',
    };
    Object.assign(this.userProfile, blankUserProfile);
  }

  setBusinessProfile(bid: string) {
    const businessProfileDoc = doc(this.firestore, '/businessProfiles/' + bid);
    console.log("setBusinessProfile:bid", bid, 'businessProfile', this.businessProfile);
    return setDoc(businessProfileDoc, this.businessProfile).then(() => {
      console.log('setBusinessProfile: Done setting BusinessProfile Doc');
    });
  }

  setBusinessUser(bid: string) {
    const businessUserDoc = doc(this.firestore, '/businessUsers/' + bid);
    setDoc(businessUserDoc, this.businessUser).then(() => {
      console.log('setBusinessUser: Done setting BusinessUser Doc');
    });
  }



  ngOnDestroy() {
    // when manually subscribing to an observable remember to unsubscribe in ngOnDestroy
    this.authStateSubscription.unsubscribe();
  }
}
